export const FARMS_COMPONENTS = {
  WEATHER: 1,
  IRRIGATION: 2,
  SOIL: 3,
  WATER: 4,
  CROP: 5,
  NONE: 6,
  SATELLITE: 7,
  IRRIWATCH: 8,
};

export const SENSORS_CATEGORY = {
  MANUAL: 1,
  PHYSICAL: 2,
  FORECAST: 3,
  SATELLITE: 4,
  FORECAST_DAILY: 5,
  CROP: 6,
  IRRIWATCH: 7,
};

export const VEGETATION_INDICES = {
  FARM_VIEW: 0,
  NDVI: 1,
  NDRE: 2,
  RECI: 3,
  MSAVI: 4,
  ELEVATION: 5,
  NDMI: 6,
};

export const SMALL_SCREEN_WIDTH = 1024;

export const USER_STATUS = {
  ACTIVE: 1,
  SUSPENDED: 2,
  PENDING: 3,
};

export const MAP_SEARCH_MODES: any = {
  LOCATION: 0,
  COORDINATES: 1,
  WGS: 2,
  LINK: 3,
};

export const WEATHER_SENSORS: any = {
  temperatureMin: "temperatureMin",
  temperatureMax: "temperatureMax",
  humidity: "humidity",
  windBearing: "windBearing",
  windSpeed: "windSpeed",
};

export const AGGREGARE_METHODS: any = {
  average: "average",
  min: "min",
  max: "max",
};

export const TASK_TYPES = {
  basic: 1,
  fertigation: 2,
};

export const TASK_STATUS_ID = {
  open: 1,
  inProgress: 3,
  completed: 2,
  canceled: 6,
};

export const IRRIWATCH_INDICES = {
  IRRIGATION: 0,
  NDVI: 30,
  SOIL_MOISTURE: 31,
  LEAF_NITROGEN: 32,
  ACTUAL_EVAPOTRANSPIRATION: 33,
  CROP_PRODUCTION: 34,
  TOTAL_CROP_PRODUCTION: 35,
  SOIL_WATER_POTENTIAL: 36,
  VARIABLE_RATE_IRRIGATION: 38,
  SOIL_TEMPERATURE: 39,
  VEGETATION_COVER: 40,
  SOIL_MOISTURE_STATUS: 41,
};
